import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { DateTime } from 'luxon'
import { EsignDisclaimerComponent } from 'src/app/dialogs/esign.disclaimer/esign.disclaimer.component'
import { SignatureComponent } from 'src/app/dialogs/signature/signature.component'
import { SignaturePopUpResult, SignatureSpot, SignatureSpotCompleted } from 'src/types/esignature'
import { environment } from 'src/environments/environment'
import { OrderService } from 'src/app/services/order.service'
import { Traveler } from 'src/types/traveler'
import { ProfileService } from 'src/app/services/profile.service'
import { ApplicationService } from 'src/app/services/application.service'
import Pusher, { Channel } from 'pusher-js'
import { CommonModule } from '@angular/common'
import { PdfViewerModule } from 'ng2-pdf-viewer'
import { MatButtonModule } from '@angular/material/button'
import { EsignDisclaimerModule } from 'src/app/dialogs/esign.disclaimer/esign.disclaimer.module'
import { ButtonComponent } from 'src/app/components/button/button.component'

@Component({
	selector: 'gwc-esignature',
	templateUrl: './esignature.component.html',
	styleUrls: ['./esignature.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		CommonModule,
		EsignDisclaimerModule,
		MatButtonModule,
		PdfViewerModule,
		SignatureComponent
	]
})

export class EsignatureComponent implements OnInit {
	public file!: string
	public signature!: SignaturePopUpResult
	public signatures: SignatureSpot[] = [
		{
			left: 60,
			top: 856,
			width: 482,
			height: 30,
			date: true, 
			date_left: 490,
			date_width: 224,
			id: 'main_signature'
		}
	]
	public pending_signatures: SignatureSpot[] = this.signatures
	public completed_signatures: SignatureSpotCompleted[] = []
	private traveler_uuid!: string
	private application_uuid!: string
	private document_uuid!: string
	private order!: string
	private model: any = null
	public saving: boolean = false
	private pusher: Pusher = new Pusher( environment.production ? 'JlhZN10vn51RtiytMnYqbhV6':'dSJVtNaH_5EXtpBFh5szO1D8', {
		cluster: '',
		wsHost: environment.production ? 'wss.govworks.com' : 'wss.dev.govworks.com',
		wsPort: 443,
		forceTLS: false,
		disableStats: true,
		enabledTransports: ['ws', 'wss']
	})

	constructor(
		private matDialog: MatDialog,
		private activatedRoute: ActivatedRoute,
		private orderService: OrderService,
		private profileService: ProfileService,
		private applicationService: ApplicationService,
		private router: Router
	) {
		const params = this.activatedRoute.snapshot.params
		this.traveler_uuid = params['traveler_uuid']
		this.application_uuid = params['application_uuid']
		this.document_uuid = params['document_uuid']
		this.order = params['order_uuid']

		this.file = `${environment.API}application/${this.application_uuid}/user/download/document/${this.document_uuid}?view=true`
	}

	ngOnInit(): void {
		this.matDialog.open(EsignDisclaimerComponent, {
			disableClose: true
		})

		this.getApplication()
	}

	private getApplication(): void {
		this.orderService.getApplication(this.traveler_uuid, this.application_uuid)
			.subscribe((response: Traveler) => {
				this.model = response.model
			})
	}

	public sign(index: number) {
		if (!this.signature) {
			this.openSignature(index)
		} else {
			this.signSpot(index)
		}
	}

	private signSpot(index: number) {
		const current = this.pending_signatures[index]

		this.completed_signatures.push({
			...current, 
			signature: this.signature
		})

		this.pending_signatures.splice(index, 1)

		this.model.signature = this.signature.name ? { name: this.signature.name} : {signature: this.signature.signature}
		this.profileService.saveProfile(this.model).subscribe()
	}

	private openSignature(index: number): void {
		const dialog = this.matDialog.open(SignatureComponent, {
			data: {
				traveler: `${this.model.first_name} ${this.model.last_name}` ,
				application_uuid: this.application_uuid,
				document_uuid: this.document_uuid
			}
		})

		dialog.beforeClosed().subscribe((response: SignaturePopUpResult | null) => {
			if (response) {
				this.signature = response
				this.signSpot(index)
			}
		})
	}

	public finishSigning(): void {
		this.saving = true
		this.applicationService.finishSigning(this.application_uuid, this.document_uuid)
			.subscribe({
				next: response => {
					this.router.navigate(['order', this.order, 'traveler', this.traveler_uuid], {relativeTo: null})
				}
			})
	}
}

<div class="gwc-esign">
	<div class="gwc-esign__header">
		<img src="assets/esignature/mygw.svg" 
			alt="my govworks"/>
	</div>
	<section class="gwc-esign__body">
		<pdf-viewer [src]="{
				url: file,
				withCredentials: true
			}"
			[render-text]="true"
			[zoom]="1"
			[original-size]="false"
			class="gwc-esign__viewer"></pdf-viewer>
		<div class="gwc-esign-overlay">
			@for (signature_spot of pending_signatures; track signature; let i = $index) {
				<div class="gwc-esign-signhere"
					[style.top.px]="signature_spot.top"
					[style.height.px]="signature_spot.height">
					<img src="assets/esignature/sign_here.svg"
						class="gwc-esign-signhere__arrow"/>
					<div class="gwc-esign-signhere__place"
						[style.left.px]="signature_spot.left"
						[style.width.px]="signature_spot.width"
						[style.height.px]="signature_spot.height">
						@if (!signature) {
							<button mat-flat-button
								(click)="sign(i)"
								class="gwc-esign-signhere__request">
								Add Signature
							</button>
						}
					</div>
				</div>
			}
			@for (signature_spot of completed_signatures; track signature) {
				<div class="gwc-esign-signhere"
					[style.top.px]="signature_spot.top"
					[style.height.px]="signature_spot.height">
					<div class="gwc-esign-signhere__place"
						[style.left.px]="signature_spot.left"
						[style.width.px]="signature_spot.width"
						[style.height.px]="signature_spot.height">
						<div class="gwc-esign-signhere__signature">
							@if (signature?.name) {
								<div>
									{{ signature?.name }}
								</div>
							} @else if (signature?.signature) {
								<img [src]="signature?.signature" alt="signature"/>
							}
							<span>
								eSigned at {{ signature_spot.signature.updated_at | date:'MM/dd/YYYY hh:mm:ss a' }}<br/>
								{{ signature_spot.signature.signature_hash }}
							</span>
						</div>
						@if (signature_spot.signature.updated_at) {
							<div [style.left.px]="signature_spot.date_left"
								[style.width.px]="signature_spot.date_width"
								class="gwc-esign-signhere__date">
								{{ signature_spot.signature.updated_at | date:'MM/dd/YYYY' }}
							</div>
						}
					</div>
				</div>
			}
		</div>
	</section>
	@if (signature) {
		<div class="gwc-esign__finish">
			<gwc-button class="gwc-esign__finish-btn"
				color="primary"
				(callback)="finishSigning()"
				[disabled]="saving"
				[spinner]="saving">
				Finish Signing Document
			</gwc-button>
		</div>
	}
</div>

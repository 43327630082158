<div class="gwc-status-message">
	@if (!needsManifest()) {
		@if ((application.status !== 'completed' 
		|| (!application.documents.length && application.document) 
		|| application.documents.length === 1)) {
			<section class="gwc-status-message__main">
				<div [innerHTML]="sanitize(getMessage())">
				</div>
				@if (application.product.type !== 'passport_photo_correction'
					&& !['shipped_out', 'delivered'].includes(application.status) 
					&& (application.product.type !== 'idp' || ['completed', 'processing', 'ready'].includes(application.status))) {
					<gwc-button style="min-width: 130px"
						color="primary"
						(callback)="buttonAction()"
						[fullwidth]="true"
						[disabled]="getButtonDisabled()"
						[spinner]="getButtonSpinner()">
						{{ getButtonLabel() }}
					</gwc-button>
				}
			</section>
		} @else if (application.documents.length > 0) {
			@if (application.product.type === 'idp') {
				<section class="gwc-status-message__main">
					<div [innerHTML]="sanitize(getMessage())">
					</div>
					@if (['completed', 'processing', 'ready'].includes(application.status)) {
						<gwc-button style="min-width: 130px"
							color="primary"
							(callback)="buttonAction()"
							[fullwidth]="true"
							[disabled]="getButtonDisabled()"
							[spinner]="getButtonSpinner()">
							{{ getButtonLabel() }}
						</gwc-button>
					}
				</section>
			} @else {
				<h3 class="gwc-status-message__header">
					Your forms are ready to be downloaded.
				</h3>
				@if (application.product.type === 'ca_passport') {
					<p class="gwc-status-message__message">
						Please save the forms on your computer and use <img src="assets/svg/adobe.svg" /> <a href="https://get.adobe.com/reader/" target="_blank">Adobe Reader 10 or higher</a> to print them.<br/>
						<strong>Don’t use your phone or tablet. The forms will not work properly.</strong>
					</p>
				}
	
				<ul class="gwc-status-message__documents">
					@for (document of application.documents; track document.uuid) {
						<li>
							<a color="primary"
								[href]="document_dowload + document.uuid">
								{{ document.label }}
								<svg>
									<use href="assets/svg/shared.svg#download"/>
								</svg>
							</a>
						</li>
					}
				</ul>
			}
		}
	} @else {
		<section class="gwc-status-message__main">
			<div class="gwc-status-message__text">
				<h3>Thanks for your submission. <br/>You're Almost Done!</h3>
				<p>
					<strong>Please call us now to complete your process at {{ orderService.phone }}</strong>
					<br/><br/>
					We'll conduct a final review of your passport documents and answer any questions you may have before you ship us your documents.
				</p>
			</div>
			<img class="gwc-status-message__govy"
				src="assets/order/img-app-review-govy@2x.png"
				alt="govy" />
		</section>
	}
	<!-- <section style="margin-top: 20px;"
		*ngIf="application.status === 'processing'"
		class="gwc-status-message__main">
		<div class="gwc-status-message__text">
			<h3>Thanks for your submission.</h3>
			<p style="color: #dd2c00">
				<strong>Please be advised the Department of State's application system is experiencing intermittent
					outages.</strong> We apologize for this inconvenience. Your application will be processed as soon as
				the Department of State becomes available. Thank you for your patience.
			</p>
		</div>
		<img class="gwc-status-message__govy"
			src="assets/order/img-app-review-govy@2x.png"
			alt="govy" />
	</section> -->
</div>

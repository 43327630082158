import { Injectable } from '@angular/core'
import { BehaviorSubject, catchError, map, of, Subject, throwError } from 'rxjs'
import { Application, Traveler } from 'src/types/traveler'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})

export class OrderService extends ApiService {
  public breadcrumbLabels: BehaviorSubject<{[key: string]: string}> = new BehaviorSubject({})
  public submittedSubject: Subject<string> = new Subject<string>()
  public itineraryUpdated: Subject<void> = new Subject<void>()
  public nextPage: Subject<string> = new Subject<string>()
  public phone: string = ''
  public active_domain: string = ''
  public active_traveler: string = ''
  public active_app: string = ''

  public getOrder() {
    return this.getRequest('order')
  }

  public getOrders() {
    return this.getRequest('orders')
  }

  public getOrderDetails(uuid: string, detail: 'summary'|'itinerary'|'travelers'|'invoices') {
    return this.getRequest(`order/${uuid}/${detail}`)
      .pipe(
        map(response => {
          if (detail === 'summary') {
            this.emitBreadcrumbs(uuid, response.order_number)
            this.phone = response.phone_number
            this.active_domain = response.source
          }

          return response
        })
      )
  }

  public updateOrder(uuid: string, data: any) {
    return this.postRequest(`order/${uuid}/update`, data)
  }

  public getTravelerDetails(traveler_uuid: string) {
    return this.getRequest(`traveler/${traveler_uuid}/compiled`)
      .pipe(
        map((response: Traveler) => {
          let photo_location = true

          if (!response.model.photo_location) {
            photo_location = false
          } else if (!response.model.photo_location.uuid && !response.model.photo_location.facility) {
            photo_location = false
          }

          this.active_traveler = response.uuid
          response.applications.forEach(application => {
            if (application.product.type === 'passport') {
              let needed_product = this.checkProductSwap(response.model, application)

              if (needed_product) {
                application.product_swap = needed_product
              }
  
              if (application.progress === 100 && !photo_location) {
                application.progress = 95
                application.status = 'pending'
              }
            }
          })

          return response
        })
      )
  }

  public getApplication(traveler_uuid: string, app_uuid: string) {
    this.active_app = app_uuid
    return this.getRequest(`traveler/${traveler_uuid}/compiled`)
      .pipe(
        map((response: Traveler) => {
          this.active_traveler = response.uuid

          let new_response: any = {}
          new_response.model = response.model

          let filtered = response.applications.filter(item => item.uuid === app_uuid)

          if (Array.isArray(response.model.emergency_contact?.address)) {
            delete response.model.emergency_contact.address
          }

          if (Array.isArray(response.model.divorced_widowed?.spouse)) {
            delete response.model.divorced_widowed.spouse
          }

          if (Array.isArray(response.model.married?.spouse)) {
            delete response.model.married.spouse
          }

          if (filtered[0]) {
            new_response.model = response.model
            new_response.application = filtered[0] 
          }

          return new_response
        }),
        catchError(error => {
          return throwError(() => error)
        })
      )
  }

  public saveProgress(app_uuid: string, progress: number) {
    return this.postRequest(`application/${app_uuid}/progress`, {progress})
  }

  private emitBreadcrumbs(key: string, value: string) {
    let current = this.breadcrumbLabels.value
    current[key] = value

    this.breadcrumbLabels.next(current)
  }

  private checkProductSwap(model: {[key: string]: any}, application: Application): string|null {
    if (application.product.type === 'visa') return null

    return null
  }

  public getReviewData(order_number: string) {
    return this.getRequest(`order/survey/site/${order_number}`)
  }

  public submitReview(order_number: string, data:any) {
    return this.postRequest(`order/survey/${order_number}`, data)
  }

  public submitRefundRequest(refundRequestId: string, userToken: string, data: any) {
    return this.postRequest(`refunds/${refundRequestId}/${userToken}`, data)
  }
}

<div class="gwc-esign-disclaimer">
  <section class="gwc-esign-disclaimer__text">
    <h1>ELECTRONIC RECORD AND SIGNATURE DISCLOSURE</h1>
    From time to time, govWorks (we, us or Company) may be required by law to provide to you certain written notices or
    disclosures. Described below are the terms and conditions for providing to you such notices and disclosures
    electronically through the govWorks system. Please read the information below carefully and thoroughly, and if you
    can access this information electronically to your satisfaction and agree to this Electronic Record and Signature
    Disclosure (ERSD), please confirm your agreement by selecting the check-box next to ‘I agree to use electronic
    records and signatures’ before clicking ‘CONTINUE’ within the govWorks system.

    <h2>Getting paper copies</h2>
    At any time, you may request from us a paper copy of any record provided or made available electronically to you by
    us. You will have the ability to download and print documents we send to you through the govWorks system during and
    immediately after the signing session and, if you elect to create a govWorks account, you may access the documents
    for a limited period of time (usually 30 days) after such documents are first sent to you. After such time, if you
    wish for us to send you paper copies of any such documents from our office to you, you will be charged a $0.00
    per-page fee. You may request delivery of such paper copies from us by following the procedure described below.


    <h2>Withdrawing your consent</h2>
    If you decide to receive notices and disclosures from us electronically, you may at any time change your mind and
    tell us that thereafter you want to receive required notices and disclosures only in paper format. How you must
    inform us of your decision to receive future notices and disclosure in paper format and withdraw your consent to
    receive notices and disclosures electronically is described below.


    <h2>Consequences of changing your mind</h2>
    If you elect to receive required notices and disclosures only in paper format, it will slow the speed at which we
    can complete certain steps in transactions with you and delivering services to you because we will need first to
    send the required notices or disclosures to you in paper format, and then wait until we receive back from you your
    acknowledgment of your receipt of such paper notices or disclosures. Further, you will no longer be able to use the
    govWorks system to receive required notices and consents electronically from us or to sign electronically documents
    from us.


    <h2>All notices and disclosures will be sent to you electronically</h2>
    Unless you tell us otherwise in accordance with the procedures described herein, we will provide electronically to
    you through the govWorks system all required notices, disclosures, authorizations, acknowledgements, and other
    documents that are required to be provided or made available to you during the course of our relationship with you.
    To reduce the chance of you inadvertently not receiving any notice or disclosure, we prefer to provide all of the
    required notices and disclosures to you by the same method and to the same address that you have given us. Thus, you
    can receive all the disclosures and notices electronically or in paper format through the paper mail delivery
    system. If you do not agree with this process, please let us know as described below. Please also see the paragraph
    immediately above that describes the consequences of your electing not to receive delivery of the notices and
    disclosures electronically from us.


    <h2>How to contact govWorks:</h2>
    You may contact us to let us know of your changes as to how we may contact you electronically, to request paper
    copies of certain information from us, and to withdraw your prior consent to receive notices and disclosures
    electronically as follows:
    To contact us by email send messages to: inquiries&#64;govworks.com


    <h2>To advise govWorks of your new email address</h2>
    To let us know of a change in your email address where we should send notices and disclosures electronically to you,
    you must send an email message to us at inquiries&#64;govworks.com and in the body of such request you must state:
    your
    previous email address, your new email address. We do not require any other information from you to change your
    email address.

    If you created a govWorks account, you may update it with your new email address through your account preferences.


    <h2>To request paper copies from govWorks</h2>
    To request delivery from us of paper copies of the notices and disclosures previously provided by us to you
    electronically, you must send us an email to inquiries&#64;govworks.com and in the body of such request you must
    state
    your email address, full name, mailing address, and telephone number. We will bill you for any fees at that time, if
    any.


    <h2>To withdraw your consent with govWorks</h2>
    To inform us that you no longer wish to receive future notices and disclosures in electronic format you may:

    i. decline to sign a document from within your signing session, and on the subsequent page, select the check-box
    indicating you wish to withdraw your consent, or you may;
    ii. send us an email to inquiries&#64;govworks.com and in the body of such request you must state your email, full
    name,
    mailing address, and telephone number. We do not need any other information from you to withdraw consent.. The
    consequences of your withdrawing consent for online documents will be that transactions may take a longer time to
    process..

    <h2>Acknowledging your access and consent to receive and sign documents electronically</h2>
    To confirm to us that you can access this information electronically, which will be similar to other electronic
    notices and disclosures that we will provide to you, please confirm that you have read this ERSD, and (i) that you
    are able to print on paper or electronically save this ERSD for your future reference and access; or (ii) that you
    are able to email this ERSD to an email address where you will be able to print on paper or save it for your future
    reference and access. Further, if you consent to receiving notices and disclosures exclusively in electronic format
    as described herein, then select the check-box next to ‘I agree to use electronic records and signatures’ before
    clicking ‘CONTINUE’ within the govWorks system.


  </section>
  <section class="gwc-esign-disclaimer__agree">
    <mat-checkbox [formControl]="agreement" color="primary" class="gwc-esign-disclaimer__agree-checkbox">
      I agree to use electronic records and signatures.<br />
      By selecting the check-box you confirm that:
      You can access and read this Electronic Record and Signature Disclosure; and
      You can print on paper this Electronic Record and Signature Disclosure, or save or send this Electronic Record and
      Disclosure to a location where you can print it, for future reference and access; and
      Until or unless you notify govWorks as described above, you consent to receive exclusively through electronic
      means
      all notices, disclosures, authorizations, acknowledgements, and other documents that are required to be provided
      or made available to you by govWorks during the course of your relationship with govWorks.
    </mat-checkbox>
    <button mat-flat-button class="gwc-esign-disclaimer__agree-btn" [disabled]="!agreement.valid"
      (click)="dialogRef.close()" color="primary">
      Continue
    </button>
  </section>
</div>
<div class="gwc-dialog gwc-signature">
	<form [formGroup]="signatureForm"
		(ngSubmit)="addSignature()">
		<h1>Confirm and add signature</h1>
		<mat-form-field class="gwc-signature__input"
			[hideRequiredMarker]="true">
			<mat-label>Full Name</mat-label>
			<input matInput 
				placeholder="Full Name"
				formControlName="name"
				autocomplete="off">
		</mat-form-field>
		<mat-tab-group class="gwc-signature__tabs"
			(selectedTabChange)="stepChanged($event)">
			<mat-tab label="Preview">
				<div class="gwc-signature__preview">
					<div>SIGNED BY</div>
					<div class="gwc-signature__preview-name">
						{{ signatureForm.getRawValue().name }}
					</div>
					<div>HF320938293</div>
				</div>
			</mat-tab>
			<mat-tab label="Draw">
				<signature-pad #signature 
					[options]="signaturePadOptions" 
					(drawEnd)="drawComplete()">
				</signature-pad>
			</mat-tab>
		</mat-tab-group>
		<mat-checkbox color="primary"
			formControlName="disclaimer"
			class="gwc-signature__disclaimer">
			By selecting the “I agree” button, I am signing this document electronically. I agree that my electronic signature is the legal equivalent of my manual/handwritten signature on this document. By selecting “I agree” using any device, means, or action, I consent to the legally binding terms and conditions of this document. I further agree that my signature on this document is as valid as if I signed the document in writing. I am also confirming that I am authorized to enter into this Agreement. 
		</mat-checkbox>
		<gwc-button 
			color="primary"
			type="submit"
			[disabled]="!signatureForm.valid"
			class="gwc-signature__btn">
			ADD SIGNATURE
		</gwc-button>
	</form>
</div>

import { FormlyFieldConfig } from "@ngx-formly/core"
import { selectOption } from "src/types/wizard"
import * as _ from 'lodash'

export const COUNTRY_OPTIONS: selectOption[] = [
  {
    label: "Afghanistan",
    value: "AF"
  },
  {
    label: "Albania",
    value: "AL"
  },
  {
    label: "Algeria",
    value: "DZ"
  },
  {
    label: "Andorra",
    value: "AD"
  },
  {
    label: "Angola",
    value: "AO"
  },
  {
    label: "Anguilla",
    value: "AI"
  },
  {
    label: "Antigua and Barbuda",
    value: "AG"
  },
  {
    label: "Argentina",
    value: "AR"
  },
  {
    label: "Armenia",
    value: "AM"
  },
  {
    label: "Aruba",
    value: "AW"
  },
  {
    label: "Australia",
    value: "AU"
  },
  {
    label: "Austria",
    value: "AT"
  },
  {
    label: "Azerbaijan",
    value: "AZ"
  },
  {
    label: "Bahamas",
    value: "BS"
  },
  {
    label: "Bahrain",
    value: "BH"
  },
  {
    label: "Bangladesh",
    value: "BD"
  },
  {
    label: "Barbados",
    value: "BB"
  },
  {
    label: "Belarus",
    value: "BY"
  },
  {
    label: "Belgium",
    value: "BE"
  },
  {
    label: "Belize",
    value: "BZ"
  },
  {
    label: "Benin",
    value: "BJ"
  },
  {
    label: "Bermuda",
    value: "BM"
  },
  {
    label: "Bhutan",
    value: "BT"
  },
  {
    label: "Bolivia",
    value: "BO"
  },
  {
    label: "Bosnia Herzegovina",
    value: "BA"
  },
  {
    label: "Botswana",
    value: "BW"
  },
  {
    label: "Brazil",
    value: "BR"
  },
  {
    label: "Brunei",
    value: "BN"
  },
  {
    label: "Bulgaria",
    value: "BG"
  },
  {
    label: "Burkina Faso",
    value: "BF"
  },
  {
    label: "Burundi",
    value: "BI"
  },
  {
    label: "Cambodia",
    value: "KH"
  },
  {
    label: "Cameroon",
    value: "CM"
  },
  {
    label: "Canada",
    value: "CA"
  },
  {
    label: "Cape Verde",
    value: "CV"
  },
  {
    label: "Cayman Islands",
    value: "KY"
  },
  {
    label: "Central African Republic",
    value: "CF"
  },
  {
    label: "Chad",
    value: "TD"
  },
  {
    label: "Chile",
    value: "CL"
  },
  {
    label: "China",
    value: "CN"
  },
  {
    label: "Colombia",
    value: "CO"
  },
  {
    label: "Comores Islands",
    value: "KM"
  },
  {
    label: "Congo[Brazzaville]",
    value: "CG"
  },
  {
    label: "Congo[Kinshasa]",
    value: "CD"
  },
  {
    label: "Cook Islands",
    value: "CK"
  },
  {
    label: "Costa Rica",
    value: "CR"
  },
  {
    label: "Cote dIvoire",
    value: "CI"
  },
  {
    label: "Croatia",
    value: "HR"
  },
  {
    label: "Cuba",
    value: "CU"
  },
  {
    label: "Cyprus",
    value: "CY"
  },
  {
    label: "Czech Republic",
    value: "CZ"
  },
  {
    label: "Denmark",
    value: "DK"
  },
  {
    label: "Djibouti",
    value: "DJ"
  },
  {
    label: "Dominica",
    value: "DM"
  },
  {
    label: "Dominican Republic",
    value: "DO"
  },
  {
    label: "Eswatini [Swaziland]",
    value: "SZ"
  },
  {
    label: "Ecuador",
    value: "EC"
  },
  {
    label: "Egypt",
    value: "EG"
  },
  {
    label: "El Salvador",
    value: "SV"
  },
  {
    label: "Equatorial Guinea",
    value: "GQ"
  },
  {
    label: "Eritrea",
    value: "ER"
  },
  {
    label: "Estonia",
    value: "EE"
  },
  {
    label: "Ethiopia",
    value: "ET"
  },
  {
    label: "Faroe Islands",
    value: "FO"
  },
  {
    label: "Fiji",
    value: "FJ"
  },
  {
    label: "Finland",
    value: "FI"
  },
  {
    label: "France",
    value: "FR"
  },
  {
    label: "French Guiana",
    value: "GF"
  },
  {
    label: "French Polynesia",
    value: "PF"
  },
  {
    label: "French West Indies",
    value: "GP"
  },
  {
    label: "North Macedonia",
    value: "MK"
  },
  {
    label: "Gabon",
    value: "GA"
  },
  {
    label: "Gambia",
    value: "GM"
  },
  {
    label: "Georgia",
    value: "GE"
  },
  {
    label: "Germany",
    value: "DE"
  },
  {
    label: "Ghana",
    value: "GH"
  },
  {
    label: "Gibraltar",
    value: "GI"
  },
  {
    label: "Greece",
    value: "GR"
  },
  {
    label: "Greenland",
    value: "GL"
  },
  {
    label: "Grenada",
    value: "GD"
  },
  {
    label: "Guam",
    value: "GU"
  },
  {
    label: "Guatemala",
    value: "GT"
  },
  {
    label: "Guinea",
    value: "GN"
  },
  {
    label: "Guinea-Bissau",
    value: "GW"
  },
  {
    label: "Guyana",
    value: "GY"
  },
  {
    label: "Haiti",
    value: "HT"
  },
  {
    label: "Honduras",
    value: "HN"
  },
  {
    label: "Hong Kong [SAR China]",
    value: "HK"
  },
  {
    label: "Hungary",
    value: "HU"
  },
  {
    label: "Iceland",
    value: "IS"
  },
  {
    label: "India",
    value: "IN"
  },
  {
    label: "Indonesia",
    value: "ID"
  },
  {
    label: "Iran",
    value: "IR"
  },
  {
    label: "Iraq",
    value: "IQ"
  },
  {
    label: "Ireland",
    value: "IE"
  },
  {
    label: "Israel",
    value: "IL"
  },
  {
    label: "Italy",
    value: "IT"
  },
  {
    label: "Jamaica",
    value: "JM"
  },
  {
    label: "Japan",
    value: "JP"
  },
  {
    label: "Jordan",
    value: "JO"
  },
  {
    label: "Kazakhstan",
    value: "KZ"
  },
  {
    label: "Kenya",
    value: "KE"
  },
  {
    label: "Kiribati",
    value: "KI"
  },
  {
    label: "North Korea[Peoples Rep.]",
    value: "KP"
  },
  {
    label: "South Korea[Rep.]",
    value: "KR"
  },
  {
    label: "Kuwait",
    value: "KW"
  },
  {
    label: "Kyrgyzstan",
    value: "KG"
  },
  {
    label: "Laos",
    value: "LA"
  },
  {
    label: "Latvia",
    value: "LV"
  },
  {
    label: "Lebanon",
    value: "LB"
  },
  {
    label: "Lesotho",
    value: "LS"
  },
  {
    label: "Liberia",
    value: "LR"
  },
  {
    label: "Libya",
    value: "LY"
  },
  {
    label: "Liechtenstein",
    value: "LI"
  },
  {
    label: "Lithuania",
    value: "LT"
  },
  {
    label: "Luxembourg",
    value: "LU"
  },
  {
    label: "Macao [SAR China]",
    value: "MO"
  },
  {
    label: "Madagascar",
    value: "MG"
  },
  {
    label: "Malawi",
    value: "MW"
  },
  {
    label: "Malaysia",
    value: "MY"
  },
  {
    label: "Maldives",
    value: "MV"
  },
  {
    label: "Mali",
    value: "ML"
  },
  {
    label: "Malta",
    value: "MT"
  },
  {
    label: "Marshall Islands",
    value: "MH"
  },
  {
    label: "Mauritania",
    value: "MR"
  },
  {
    label: "Mauritius",
    value: "MU"
  },
  {
    label: "Mayotte",
    value: "YT"
  },
  {
    label: "Mexico",
    value: "MX"
  },
  {
    label: "Micronesia ",
    value: "FM"
  },
  {
    label: "Moldova",
    value: "MD"
  },
  {
    label: "Monaco",
    value: "MC"
  },
  {
    label: "Mongolia",
    value: "MN"
  },
  {
    label: "Montserrat",
    value: "MS"
  },
  {
    label: "Morocco",
    value: "MA"
  },
  {
    label: "Mozambique",
    value: "MZ"
  },
  {
    label: "Myanmar",
    value: "MM"
  },
  {
    label: "Namibia",
    value: "NA"
  },
  {
    label: "Nauru",
    value: "NR"
  },
  {
    label: "Nepal",
    value: "NP"
  },
  {
    label: "Netherlands Antilles",
    value: "AN"
  },
  {
    label: "Netherlands",
    value: "NL"
  },
  {
    label: "New Caledonia",
    value: "NC"
  },
  {
    label: "New Zealand",
    value: "NZ"
  },
  {
    label: "Nicaragua",
    value: "NI"
  },
  {
    label: "Niger",
    value: "NE"
  },
  {
    label: "Nigeria",
    value: "NG"
  },
  {
    label: "Niue",
    value: "NU"
  },
  {
    label: "Norfolk Island",
    value: "NF"
  },
  {
    label: "Northern Mariana Isl.",
    value: "MP"
  },
  {
    label: "Norway",
    value: "NO"
  },
  {
    label: "Oman",
    value: "OM"
  },
  {
    label: "Pakistan",
    value: "PK"
  },
  {
    label: "Palau Islands",
    value: "PW"
  },
  {
    label: "Panama",
    value: "PA"
  },
  {
    label: "Papua New Guinea",
    value: "PG"
  },
  {
    label: "Paraguay",
    value: "PY"
  },
  {
    label: "Peru",
    value: "PE"
  },
  {
    label: "Philippines",
    value: "PH"
  },
  {
    label: "Poland",
    value: "PL"
  },
  {
    label: "Portugal",
    value: "PT"
  },
  {
    label: "Puerto Rico",
    value: "PR"
  },
  {
    label: "Qatar",
    value: "QA"
  },
  {
    label: "Reunion",
    value: "RE"
  },
  {
    label: "Romania",
    value: "RO"
  },
  {
    label: "Russia",
    value: "RU"
  },
  {
    label: "Rwanda",
    value: "RW"
  },
  {
    label: "Samoa[American]",
    value: "AS"
  },
  {
    label: "Samoa",
    value: "WS"
  },
  {
    label: "San Marino",
    value: "SM"
  },
  {
    label: "Sao Tome & Principe",
    value: "ST"
  },
  {
    label: "Saudi Arabia",
    value: "SA"
  },
  {
    label: "Senegal",
    value: "SN"
  },
  {
    label: "Serbia",
    value: "RS"
  },
  {
    label: "Seychelles",
    value: "SC"
  },
  {
    label: "Sierra Leone",
    value: "SL"
  },
  {
    label: "Singapore",
    value: "SG"
  },
  {
    label: "Slovak Republic",
    value: "SK"
  },
  {
    label: "Slovenia",
    value: "SI"
  },
  {
    label: "Solomon Islands",
    value: "SB"
  },
  {
    label: "Somalia",
    value: "SO"
  },
  {
    label: "South Africa",
    value: "ZA"
  },
  {
    label: "Spain",
    value: "ES"
  },
  {
    label: "Sri Lanka",
    value: "LK"
  },
  {
    label: "St.Kitts-Nevis",
    value: "KN"
  },
  {
    label: "St.Lucia",
    value: "LC"
  },
  {
    label: "St.Vincent & Grenadines",
    value: "VC"
  },
  {
    label: "Sudan",
    value: "SD"
  },
  {
    label: "Montenegro",
    value: "ME"
  },
  {
    label: "Suriname",
    value: "SR"
  },
  {
    label: "South Sudan",
    value: "SS"
  },
  {
    label: "Sweden",
    value: "SE"
  },
  {
    label: "Switzerland",
    value: "CH"
  },
  {
    label: "Syria",
    value: "SY"
  },
  {
    label: "Taiwan[Rep. of China]",
    value: "TW"
  },
  {
    label: "Tajikistan",
    value: "TJ"
  },
  {
    label: "Tanzania",
    value: "TZ"
  },
  {
    label: "Thailand",
    value: "TH"
  },
  {
    label: "Timor Leste",
    value: "TL"
  },
  {
    label: "Togo",
    value: "TG"
  },
  {
    label: "Tonga",
    value: "TO"
  },
  {
    label: "Trinidad & Tobago",
    value: "TT"
  },
  {
    label: "Tunisia",
    value: "TN"
  },
  {
    label: "Turkey",
    value: "TR"
  },
  {
    label: "Turkmenistan",
    value: "TM"
  },
  {
    label: "Turks & Caicos Isl.",
    value: "TC"
  },
  {
    label: "Tuvalu",
    value: "TV"
  },
  {
    label: "Uganda",
    value: "UG"
  },
  {
    label: "Ukraine",
    value: "UA"
  },
  {
    label: "United Arab Emirates",
    value: "AE"
  },
  {
    label: "United Kingdom",
    value: "GB"
  },
  {
    label: "United States",
    value: "US"
  },
  {
    label: "Uruguay",
    value: "UY"
  },
  {
    label: "Uzbekistan",
    value: "UZ"
  },
  {
    label: "Vanuatu",
    value: "VU"
  },
  {
    label: "Vatican",
    value: "VA"
  },
  {
    label: "Venezuela",
    value: "VE"
  },
  {
    label: "Vietnam",
    value: "VN"
  },
  {
    label: "Virgin Islands [U.S.A.]",
    value: "VI"
  },
  {
    label: "Virgin Islands [British]",
    value: "VG"
  },
  {
    label: "Yemen",
    value: "YE"
  },
  {
    label: "Zambia",
    value: "ZM"
  },
  {
    label: "Zimbabwe",
    value: "ZW"
  },
  {
    label: "Curacao",
    value: "CW"
  },
  {
    label: "Sint Maarten",
    value: "SX"
  },
  {
    label: "Saint Maarten",
    value: "MF"
  }
]

export const STATE_PROVINCE_LIST = {
  "US": [
    {
      label: "Alaska",
      value: "AK"
    },
    {
      label: "Alabama",
      value: "AL"
    },
    {
      label: "Arkansas",
      value: "AR"
    },
    {
      label: "American Samoa",
      value: "ASM"
    },
    {
      label: "Arizona",
      value: "AZ"
    },
    {
      label: "California",
      value: "CA"
    },
    {
      label: "Colorado",
      value: "CO"
    },
    {
      label: "Connecticut",
      value: "CT"
    },
    {
      label: "District of Columbia",
      value: "DC"
    },
    {
      label: "Delaware",
      value: "DE"
    },
    {
      label: "Florida",
      value: "FL"
    },
    {
      label: "Georgia",
      value: "GA"
    },
    {
      label: "Guam",
      value: "GUM"
    },
    {
      label: "Hawaii",
      value: "HI"
    },
    {
      label: "Iowa",
      value: "IA"
    },
    {
      label: "Idaho",
      value: "ID"
    },
    {
      label: "Illinois",
      value: "IL"
    },
    {
      label: "Indiana",
      value: "IN"
    },
    {
      label: "Kansas",
      value: "KS"
    },
    {
      label: "Kentucky",
      value: "KY"
    },
    {
      label: "Louisiana",
      value: "LA"
    },
    {
      label: "Massachusetts",
      value: "MA"
    },
    {
      label: "Maryland",
      value: "MD"
    },
    {
      label: "Maine",
      value: "ME"
    },
    {
      label: "Michigan",
      value: "MI"
    },
    {
      label: "Minnesota",
      value: "MN"
    },
    {
      label: "North Mariana Islands",
      value: "MNP"
    },
    {
      label: "Missouri",
      value: "MO"
    },
    {
      label: "Mississippi",
      value: "MS"
    },
    {
      label: "Montana",
      value: "MT"
    },
    {
      label: "North Carolina",
      value: "NC"
    },
    {
      label: "North Dakota",
      value: "ND"
    },
    {
      label: "Nebraska",
      value: "NE"
    },
    {
      label: "New Hampshire",
      value: "NH"
    },
    {
      label: "New Jersey",
      value: "NJ"
    },
    {
      label: "New Mexico",
      value: "NM"
    },
    {
      label: "Nevada",
      value: "NV"
    },
    {
      label: "New York",
      value: "NY"
    },
    {
      label: "Ohio",
      value: "OH"
    },
    {
      label: "Oklahoma",
      value: "OK"
    },
    {
      label: "Oregon",
      value: "OR"
    },
    {
      label: "Pennsylvania",
      value: "PA"
    },
    {
      label: "Puerto Rico",
      value: "PRI"
    },
    {
      label: "Rhode Island",
      value: "RI"
    },
    {
      label: "South Carolina",
      value: "SC"
    },
    {
      label: "South Dakota",
      value: "SD"
    },
    {
      label: "Tennessee",
      value: "TN"
    },
    {
      label: "Texas",
      value: "TX"
    },
    {
      label: "Utah",
      value: "UT"
    },
    {
      label: "Virginia",
      value: "VA"
    },
    {
      label: "U.S. Virgin Islands",
      value: "VIR"
    },
    {
      label: "Vermont",
      value: "VT"
    },
    {
      label: "Washington",
      value: "WA"
    },
    {
      label: "Wisconsin",
      value: "WI"
    },
    {
      label: "West Virginia",
      value: "WV"
    },
    {
      label: "Wyoming",
      value: "WY"
    },
    {
      label: "Midway Islands",
      value: "XMI"
    }
  ],
  "CA": [
    {
      label: "Alberta",
      value: "AB"
    },
    {
      label: "British Columbia",
      value: "BC"
    },
    {
      label: "Manitoba",
      value: "MB"
    },
    {
      label: "New Brunswick",
      value: "NB"
    },
    {
      label: "New Foundland and Labrador",
      value: "NL"
    },
    {
      label: "Northwest Territories",
      value: "NT"
    },
    {
      label: "Nova Scotia",
      value: "NS"
    },
    {
      label: "Nunavut",
      value: "NU"
    },
    {
      label: "Ontario",
      value: "ON"
    },
    {
      label: "Prince Edward Island",
      value: "PE"
    },
    {
      label: "Quebec",
      value: "QC"
    },
    {
      label: "Saskatchewan",
      value: "SK"
    },
    {
      label: "Yukon",
      value: "YT"
    }
  ]
}

const ADDRESS_NUMBER_FIELD = {
  key: "number",
  type: "input",
  props: {
    label: "Number",
    maxLength: 5,
    pattern: "^[ \\-a-zA-Z0-9]*$",
    required: true
  },
  className: "gwc-form__field gwc-form__field--grow gwc-form__field--small",
  validators: {
    validation: [
      "pobox"
    ]
  }
}

const ADDRESS_STREET_FIELD = {
  key: "street",
  type: "input",
  props: {
    label: "Address Street",
    maxLength: 30,
    required: true
  },
  className: "gwc-form__field gwc-form__field--grow",
  validators: {
    validation: ["pobox"]
  }
}

const ADDRESS_STREET_1_FIELD = {
  key: "address_1",
  type: "input",
  className: "gwc-form__field--hidden"
}

const ADDRESS_STREET_2_FIELD = {
  key: "address_2",
  type: "input",
  props: {
    label: "Apt",
    required: false,
    maxLength: 5
  },
  className: "gwc-form__field gwc-form__field--grow gwc-form__field--small",
  validators: {
    validation: ["pobox"]
  }
}

const ADDRESS_CITY_FIELD = {
  key: "city",
  type: "name-field",
  props: {
    label: "City",
    maxLength: 30,
    required: true
  },
  className: "gwc-form__field gwc-form__field--grow"
}

const ADDRESS_STATE_FIELD = {
  key: "state",
  type: "autocomplete",
  props: {
    required: true,
    mappedOptions: STATE_PROVINCE_LIST,
  },
  expressions: {
    'props.label': (field: FormlyFieldConfig) => {
      const parent = field.parent?.parent
      const country = parent ? parent?.model?.country || parent.parent?.props?.['country'] : null
      
      return country === 'US' ? 'State' : 'Province'
    },
    'props.options': (field: FormlyFieldConfig) => {
      const parent = field.parent?.parent
      const country = parent ? parent?.model?.country || parent.parent?.props?.['country'] : null

      return field.props?.['mappedOptions'][country]
    },
    'hide': (field: FormlyFieldConfig) => {
      const parent = field.parent?.parent
      const country = parent ? parent?.model?.country || parent.parent?.props?.['country'] : null

      return !(country && field.props?.['mappedOptions'][country])
    }
  },
  className: "gwc-form__field gwc-form__field--grow"
}

const ADDRESS_ZIP_FIELD =  {
  key: "zip",
  type: "input",
  props: {
    label: "Zip",
    required: true
  },
  className: "gwc-form__field gwc-form__field--grow gwc-form__field--small",
  expressions: {
    'props.pattern': (field: FormlyFieldConfig) => {
      const country = field.formControl?.parent?.get('country')?.value || field.model?.country || field.parent?.parent?.parent?.props?.['country']

      return country === 'US' ? '[0-9]{5}' : '[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJKLMNPRSTVWXYZ] \\d[ABCEGHJKLMNPRSTVWXYZ]\\d'
    },
    'props.maxLength': (field: FormlyFieldConfig) => {
      const country = field.formControl?.parent?.get('country')?.value || field.model?.country

      return country === 'US' ? 5 : 7
    },
  }
}

export const ADDRESS_AUTOCOMPLETE: FormlyFieldConfig[] = [
  {
    key: "place_id",
    className: "gwc-form__field--hidden",
    type: "input"
  },
  {
    key: "address_line",
    type: "google-address",
    props: {
      required: true
    },
    expressions: {
      'className': (field: FormlyFieldConfig) => {
        const place_id = field.formControl?.parent?.get('place_id')?.value || null

        return place_id ? 'gwc-form__field--hidden' : 'gwc-form__field gwc-form__field--grow'
      },
      'props.country': (field: FormlyFieldConfig) => {
        const country = field.formControl?.parent?.get('country')?.value || field.parent?.props?.['country'] || ''

        return country
      }
    }
  },
  {
    fieldGroup: [
      {
        fieldGroup: [
          ADDRESS_NUMBER_FIELD,
          ADDRESS_STREET_FIELD,
          ADDRESS_STREET_2_FIELD,
          ADDRESS_STREET_1_FIELD
        ],
        fieldGroupClassName: "gwc-form__field-group"
      },
      {
        fieldGroup: [
          ADDRESS_CITY_FIELD,
          ADDRESS_STATE_FIELD,
          ADDRESS_ZIP_FIELD
        ],
        fieldGroupClassName: "gwc-form__field-group"
      },
    ],
    expressions: {
      'fieldGroupClassName': (field: FormlyFieldConfig) => {
        const place_id = field.formControl?.get('place_id')?.value

        return place_id ? '': 'gwc-form__field-group--hidden'
      }
    }
  }
]

export const ADDRESS_COUNTRY_AUTOCOMPLETE = [
  {
    key: "country",
    type: "country-autocomplete",
    className: "gwc-form__field gwc-form__field--tight"
  },
  {
    type: "address-autocomplete"
  }
]

import { Injectable } from '@angular/core'
import { Scan } from 'src/types/wizard'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})

export class ScannerService extends ApiService {
  public sendText(phone: string, documents: Scan[], profile_uuid: string, traveler_uuid: string) {
    return this.postRequest(`scanner/client/start`, {
      domain: 'govworks',
      phone,
      profile_uuid,
      traveler_uuid,
      documents
    })
  }
  
  public getScanData(token: string) {
    return this.getRequest(`scanner/open/${token}`)
  }

  public uploadFile(document_uuid: string, file: File, plugin?: string) {
    let fd = new FormData()
    fd.append('file', file)

    if (plugin) {
      fd.append('plugin', plugin)
    }

    return this.postRequest(`scanner/document/${document_uuid}/upload`, fd)
  }

  public confirmDocument(document_uuid: string) {
    return this.postRequest(`scanner/document/${document_uuid}/confirm`, {})
  }

  public getDocumentStatus(document_uuid: string) {
    return this.getRequest(`scanner/client/document/${document_uuid}/info`)
  }
}

@switch (step) {
	@case ('upload') {
		<div class="gwc-secure-scan__phone"
			[class.gwc-secure-scan__phone--error]="formControl.touched && formControl.invalid">
			<h4>{{ is_photo ? "Use your phone's camera to take a passport style photo." : "Use your mobile phone's camera to scan your documents" }}</h4>
				<mat-form-field floatLabel="always">
					<mat-label>Enter Mobile Number</mat-label>
					<input matInput 
						mask="(000) 000-0000"
						[formControl]="phone">
				</mat-form-field>
			<button mat-stroked-button
				color="primary"
				(click)="sendText()">
				Send Text
			</button>
			@if (formControl.touched && formControl.invalid){
				<div class="gwc-secure-scan__error">
					Please press "Send Text" to start the process before proceeding.
				</div>
			}
		</div>
		@if (props['allow_upload']) {
			<div class="gwc-secure-scan__upload">
				Or, <a (click)="uploadFile(0)">upload files</a> from your computer
				<input #input 
					type="file" 
					[multiple]="false"
					class="gwc-secure-scan__input"
					accept="image/png, image/jpeg"
					(change)="fileUploaded($event)"/>
			</div>
		}
	}
	@case ('phone') {
		<h4 class="gwc-secure-scan__message">Please use the link in the text message to scan your documents</h4>
		<ul class="gwc-secure-scan__documents">
			@for (document of documents; track document.document_uuid) {
				<li>
					<div class="gwc-secure-scan__label">
						@if (listening === document.document_uuid || !completed.includes(document.document_uuid)) {
							<mat-spinner [class.gwc-secure-scan__hidden]="listening !== document.document_uuid && !completed.includes(document.document_uuid)"
								diameter="20">
							</mat-spinner>
						} @else if (completed.includes(document.document_uuid)) {
							<img src="assets/scanner/checkmark.svg"/>
						}
						{{ document.friendly_name }}
					</div>
				</li>
			}
		</ul>
	}
	@case ('crop') {
		<image-cropper class="gwc-secure-scan__cropper"
			[imageChangedEvent]="imageChangedEvent"
			[maintainAspectRatio]="true"
			[aspectRatio]="1.59 / 1"
			[canvasRotation]="rotate"
			format="png"
			(imageCropped)="imageCropped($event)"
			(imageLoaded)="imageLoaded($event)"
			(cropperReady)="cropperReady()"
			(loadImageFailed)="loadImageFailed()"
		></image-cropper>
	}
	@case ('completed') {
		@if (!is_photo) {
			<h4 class="gwc-secure-scan__message">
				All documents have been scanned.
			</h4>
		}
		@for (document of documents; track document.document_uuid) {
			<div class="gwc-secure-scan__completed">
				<div class="gwc-secure-scan__label">
					<img src="assets/scanner/checkmark.svg"/>
					{{ document.friendly_name }}
				</div>
				<img class="gwc-secure-scan__completed-preview"
					[src]="getDocumentImg(document.document_uuid)"/>
			</div>
		}
	}
}
import { FormlyFieldConfig } from "@ngx-formly/core"
import { DateExpression } from "src/types/wizard"
import { DateTime } from 'luxon'
import * as _ from 'lodash'
import { AbstractControl } from "@angular/forms"

export const FORM_TO_COMBO: FormlyFieldConfig[] = [
  {
    key: "from",
    type: "datepicker",
    props: {
      view: "multi-year",
      label: "From",
      required: true,
      before: [
        {
          parent: "local",
          key: "to",
          message: "Date can’t be after the to"
        },
        {
          date: "now",
          message: "Date can’t be in the future"
        }
      ],
      after: [
        {
          parent: "global",
          key: "date_of_birth",
          message: "Date can't be before Date of Birth"
        }
      ]
    },
    className: "gwc-form__field gwc-form__field--grow"
  },
  {
    key: "to",
    type: "datepicker",
    props: {
      view: "multi-year",
      label: "To",
      required: true,
      before: [
        {
          date: "now",
          message: "Date can’t be in the future"
        }
      ],
      after: [
        {
          parent: "local",
          key: "from",
          message: "Date can’t be before the from"
        },
        {
          parent: "global",
          key: "date_of_birth",
          message: "Date can't be before Date of Birth"
        }
      ]
    },
    expressions: {
      'hide': (field: FormlyFieldConfig) => {
        const current = field.model?.current
        
        return current ? true : false
      }
    },
    className: "gwc-form__field gwc-form__field--grow"
  },
  {
    key: "current",
    type: "gwa-checkbox",
    props: {
      label: "Current",
      required: false
    },
    className: "gwc-form__field gwc-form__field--auto"
  }
]

export const buildDates = (field: FormlyFieldConfig, type: 'min'|'max'): DateTime | null => {
  const conditions = field.props?.[type === 'max' ? 'before' : 'after']

  let result: DateTime | null = null

  if (conditions) {
    let root: FormlyFieldConfig | undefined = field.parent
  
    while (root && root.parent) {
      root = root.parent
    }
  
    const model = root?.model || field.model
  
    conditions.forEach((condition: DateExpression) => {
      let compare_to: DateTime | null = null

      if (condition.date === 'now') {
        compare_to = DateTime.now()
      } else if (condition.date) {
        compare_to = DateTime.fromFormat(condition.date, 'MM/dd/yyyy')
      } else if (condition.key) {
        let value: string | undefined

        if (condition.parent === 'local') {
          value = _.get(field.model, condition.key)
        } else {
          value = _.get(model, condition.key)
        }

        if (value) {
          compare_to = DateTime.fromFormat(value, 'MM/dd/yyyy')
        }
      }

      if (compare_to) {
        if (!result || (type === 'min' && result.toMillis() < compare_to.toMillis()) || (type === 'max' && result.toMillis() > compare_to.toMillis())) {
          result = compare_to

          _.set(field, `validation.messages.${type}`, condition.message)
          _.set(field, `validators.${type}`, DateValidator(result, type))
        }
      }
    })
  }

  return result
}

const DateValidator = (baseDate: DateTime, type: 'min'|'max') => {
  return (control: AbstractControl, field: FormlyFieldConfig): {[key: string]: any} | null => {
    if (!control.value || control.value.length < 10) {
      return {'min': true}
    }

    const controlDate = DateTime.fromFormat(control.value, 'MM/dd/yyyy')

    if (type === 'max') {
      return controlDate.toMillis() >= baseDate.toMillis() ? null : {'max': true}
    }

    return controlDate.toMillis() < baseDate.toMillis() ? null : {'min': true}
  }
}

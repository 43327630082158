import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper'
import { OrderService } from 'src/app/services/order.service'
import { ScannerService } from 'src/app/services/scanner.service'
import { UserService } from 'src/app/services/user.service'
import { environment } from 'src/environments/environment'
import { User } from 'src/types/user'
import { Scan } from 'src/types/wizard'

@Component({
	selector: 'gwc-secure-scan',
	templateUrl: './secure.scan.component.html',
	styleUrls: ['./secure.scan.component.scss']
})

export class SecureScanComponent extends FieldType<FieldTypeConfig> implements OnInit {
	@ViewChild('input') file_input!: ElementRef

	public step: string = ''
	public phone = new FormControl('', Validators.required)
	public uploading_file_index: number|undefined
	public is_photo: boolean = false
	public rotate: number = 0
	public documents: {
		document_uuid: string
		friendly_name: string
		type: string
	}[] = []
	public scans: Scan[] = []
	public listening: string = ''
	public completed: string[] = []
	public firstScanIndex: number = 0
	imageChangedEvent: any = ''
	croppedImage: any = ''

	constructor(
		private scannerService: ScannerService,
		private userService: UserService,
		private orderService: OrderService,
		private ref: ChangeDetectorRef
	) {
		super()
	}

	ngOnInit(): void {
		this.prefillPhone()
		this.getScans()
	}

	private prefillPhone(): void {
		this.userService.getUser()
			.subscribe((response: User) => {
				let phone = response.phones.filter(phone => phone.type === 'mobile')[0]

				if (phone && phone.value) {
					this.phone.patchValue(phone.value)
					this.phone.markAsTouched()
					this.phone.markAsDirty()
				}
			})
	}

	private getScans() {
		let docs = this.field.fieldGroup
		let value: any = this.formControl.value
		let index = 0

		if (docs) {
			docs?.forEach(doc => {
				if (doc.key) {
					let key = doc.key.toString()
					
					if (key === 'online_passport_photo') {
						this.is_photo = true
					}
					
					if (!value[key]) {
						this.scans.push({
						 friendly_name: doc.props?.['friendly_name'],
						 type: key,
						 message: doc.props?.['message']
						})
					} else {
						this.documents.push({
							type: key,
							document_uuid: value[key],
							friendly_name: doc.props?.['friendly_name']
						})
						this.completed.push(value[key])
						index += 1
					}
				}
			})

			this.firstScanIndex = index
			this.step = this.scans.length === 0 ? 'completed' : 'upload'
		}
	}

	public sendText() {
		if (this.scans.length > 0) {
			this.scannerService.sendText(
				this.phone.value as string, 
				this.scans,
				this.model.profile_uuid,
				this.orderService.active_traveler).subscribe(response => {
					this.documents = [...this.documents, ...response.documents]
					this.step = 'phone'
					this.subscribeForDocumentUpdates(this.firstScanIndex)
					this.ref.detectChanges()
				})
		}
	}

	private subscribeForDocumentUpdates(index: number) {
		this.listening = this.documents[index].document_uuid

		let subscription = setInterval(() => {
			this.scannerService.getDocumentStatus(this.listening)
				.subscribe(response => {
					if (response.status === 'confirmed') {
						let group: any = this.formControl

						group.controls[response.type].patchValue(response.uuid)
						this.model[response.type] = response.uuid
						clearInterval(subscription)
						this.completed.push(response.uuid)

						if ((index + 1) < this.documents.length) {
							this.subscribeForDocumentUpdates(index + 1)
						} else {
							this.listening = ''
							this.step = 'completed'
						}

						this.ref.detectChanges()
					}
				})
		}, 3000)
	}

	public uploadFile(index: number): void {
		this.uploading_file_index = index
		this.file_input.nativeElement.click()
	}

	public fileUploaded($event: any) {
		let file_list = this.file_input.nativeElement.files as FileList

		if (file_list.length > 0) {
			this.imageChangedEvent = event
			this.step = 'crop'
		}
	}

	public getDocumentImg(document_uuid: string): string {
		return `${environment.API}scanner/document/${document_uuid}/data`
	}

	fileChangeEvent(event: any): void {
			this.imageChangedEvent = event;
	}
	imageCropped(event: ImageCroppedEvent) {
			this.croppedImage = event.base64;
	}
	imageLoaded(image: LoadedImage) {
			// show cropper
	}
	cropperReady() {
			// cropper ready
	}
	loadImageFailed() {
			// show message
	}

	// private dataURLtoFile(dataurl: string, filename: string): File {
	//   let arr = dataurl.split(',')
	//   let mime = arr[0].match(/:(.*?);/)?.[1]
	//   let bstr = Buffer.from(arr[1]).toString()
	//   let n = bstr.length
	//   let u8arr = new Uint8Array(n)
				
	//   while(n--){
	//     u8arr[n] = bstr.charCodeAt(n)
	//   }
		
	//   return new File([u8arr], filename, {type:mime})
	// }
}

<mat-form-field class="gwc-datepicker"
  [class.mat-form-field-should-float]="formControl.value">
  <mat-label>{{ props.label }}</mat-label>
  <input matInput 
    [required]="required"
    mask="M0/d0/0000"
    [value]="formControl.value || ''"
    [leadZeroDateTime]="true"
    [dropSpecialCharacters]="false"
		[formControl]="formControl">
  <input type="hidden" 
    [value]="getDate()"
    (dateChange)="dateSelected($event)"
    [min]="props['minDate']"
    [max]="props['maxDate']"
    [matDatepicker]="datepicker">
  <mat-datepicker-toggle matSuffix 
    [for]="datepicker">
  </mat-datepicker-toggle>
  <mat-datepicker #datepicker
    [startView]="props['view']">
  </mat-datepicker>
  <mat-error>
    {{ getErrorMessage() }}
  </mat-error>
</mat-form-field>

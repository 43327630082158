import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core'
import { ApplicationService } from 'src/app/services/application.service'
import { Application, ApplicationDocument } from 'src/types/traveler'
import Pusher, { Channel } from 'pusher-js'
import { OrderService } from 'src/app/services/order.service'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { MatDialog } from '@angular/material/dialog'
import { environment } from 'src/environments/environment'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { MatButtonModule } from '@angular/material/button'
import { Subject, switchMap, takeUntil, timer } from 'rxjs'

@Component({
  selector: 'gwc-status-message',
  templateUrl: './status.message.component.html',
  styleUrls: ['./status.message.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatButtonModule
  ]
})

export class StatusMessageComponent implements OnInit, OnDestroy {
  @Input() application!: Application
  @Input() swapping: boolean = false
  @Input() submitting: boolean = false
  @Output() callback = new EventEmitter()

  public document_dowload!: string
  public message: string = ''
  public documents: ApplicationDocument[] = []
  private production = environment.production
  private pusher: Pusher = new Pusher( this.production ? 'JlhZN10vn51RtiytMnYqbhV6':'dSJVtNaH_5EXtpBFh5szO1D8', {
    cluster: '',
    wsHost: this.production ? 'wss.govworks.com' : 'wss.dev.govworks.com',
    wsPort: 443,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss']
  })
  private closeTimer = new Subject<boolean>()
  private INTERVAL = 30000

  constructor(
    private applicationService: ApplicationService,
    private matDialog: MatDialog,
    public orderService: OrderService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.document_dowload = `${environment.API}application/${this.application.uuid}/user/download/document/`

    if (this.application.status === 'processing') {
      this.subscribeForAppUpdates()
    }

    this.checkApplicationNextSteps()
  }

  private checkApplicationNextSteps(): void {
    if (this.needsManifest()) {
      this.openShippingConfirmation()
    }
  }

  public sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  public getMessage(): string {
    if (this.application.product.desired) {
      return `Based on your answers on your <strong>${ this.application.product.label }</strong> application, we’ve detected that your application requires a <strong>${ this.application.product.desired_label }</strong> application instead.`
    } 

    switch (this.application.status) {
      case 'ready':
        return 'Your application is ready to be submitted.'
      case 'completed': 
        if (!this.application.packet_approved) {
          return 'Your application is ready to be reviewed.'
        }

        if (this.application.product.subtype === 'idp') {
          return 'Your application is ready to be signed and submitted.'
        }
        break
      case 'processing':
        return `Your application is being submitted. <br/>
          ${this.message }`
      case 'shipped_out': 
        return 'Your new documents are on their way.'
      case 'processed': 
        return 'Your application was submitted.'
      case 'dropped_off':
      case 'documents_processing': 
        if (this.application.product.type === 'passport') {
          return 'Your application is being processed by the government.'
        } else if (this.application.product.type === 'idp') {
          return '<strong>Congratulations!</strong> Your IDP application has been sent to AAA and is currently being processed.'
        } else {
          return 'Your application is being processed.'
        }
    }

    if (this.application.documents.length === 0) {
      return ''
    }

    return this.application.documents.length === 1 ? 'Your application is ready to be downloaded.' : 'Your forms are ready to be downloaded.'
  }

  public getButtonLabel(): string {
    if (this.application.product.desired) {
      return 'Update Product'
    } 

    if (this.application.status === 'ready') return 'Submit'

    if (this.application.status === 'completed') {
      if (this.application.product.subtype === 'idp') {
        return 'Sign and Submit'
      } else if (this.application.packet_approved ) return 'Submitted' 

      return 'Review'
    }

    return 'Download'
  }

  public getButtonDisabled(): boolean {
    if (this.application.product.desired) {
      return this.swapping
    }

    if (this.application.status === 'processing') {
      return this.submitting
    }

    return false
  }

  public getButtonSpinner(): boolean {
    if (this.application.product.desired) {
      return this.swapping
    }

    return this.application.status === 'processing'
    if (this.application.status === 'processing') {
      return this.submitting
    }

    return false
  }

  public buttonAction(): void {
    if (this.application.product.desired) {
      this.productSwap()
    } else {
      if (this.application.status === 'ready') {
        this.submitApplication()
      } else if (this.application.packet_approved) {
        if (this.application.product.subtype === 'idp') {
          this.callback.emit({action: 'esignature', document_uuid: this.application.documents[0].uuid})
        } else if (!this.needsManifest()) {
          this.callback.emit({action: 'download'})
        } else {
          if (this.application.manifest && this.application.manifest.status !== 'unmanifested') {
            this.callback.emit({action: 'download'})
          } else {
            this.openShippingConfirmation()
          }
        }
        
      } else {   
        this.callback.emit({action: 'preview'})
      }
    }
  }

  private submitApplication(): void {
    this.application.status = 'processing'

    this.applicationService.submitApplication(this.application.uuid)
      .subscribe(response => {
        this.subscribeForAppUpdates()
      })

    this.callback.emit({action: 'submit'})
  }

  private subscribeForAppUpdates(): void {
    this.submitting = true
    const channel: Channel = this.pusher.subscribe(`engine-events-app-${this.application.uuid}`)

    channel.bind('engine-feedback', (message: any) => {
      this.message = message.message
      this.callback.emit({action: 'message', data: message})

      if (message.percentage === 1) {
        this.endSubmitAndTimer()

        if (this.application.product.subtype === 'idp') {
          this.callback.emit({action: 'refresh'})
        } else {
          this.refreshTraveler()
        }
      }
    })
    this.getPoll()
  }

  private productSwap(): void {
    if (this.application.product.desired) {
      this.swapping = true

      this.applicationService.getProduct(this.application)
        .subscribe(response => {
          if (response[0]) {
            this.applicationService.changeProduct(this.application.uuid, response[0].uuid)
              .subscribe(response => {
                this.refreshTraveler()
              })
          }
        })
    }
  }

  private getPoll(): void {
    timer(0, this.INTERVAL).pipe(
      switchMap(() => this.applicationService.getStatus(this.application.uuid)),
      takeUntil(this.closeTimer)
    ).subscribe((response) => {
      if(response.status === 'completed') {
        this.endSubmitAndTimer()
        this.refreshTraveler()
      }
    })
  }

  private openShippingConfirmation(): void {
    this.callback.emit({action: 'ship'})
  }

  public needsManifest(): boolean {
    if (this.application.product.country_code === 'CA') {
      return false
    }
    
    if (this.application.status === 'completed' && 
        this.application.packet_approved && 
        !this.application.is_mailaway &&
        (!this.application.manifest || this.application.manifest.status === 'unmanifested')
    ) {
      return true
    }

    return false
  }

  private endSubmitAndTimer(): void {
    // this.submitting = false
    this.closeTimer.next(true)
    this.unsubscribePusher()
  }

  private refreshTraveler(): void {
    this.callback.emit({ action: 'refresh' })
  }

  private unsubscribePusher(): void {
    this.pusher.unsubscribe(`engine-events-app-${this.application.uuid}`)
  }

  ngOnDestroy(): void {
    this.endSubmitAndTimer()
    this.closeTimer.unsubscribe()
  }

  public downloadPacket() {
    const packet =this.application.documents.filter(document => document.type === 'packet')[0]

    if (packet) {
      window.open(this.document_dowload + packet.uuid, '_blank')
      console.log(this.document_dowload + packet.uuid)
    }
  }
}

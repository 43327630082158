import { Component, NgZone } from '@angular/core'
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms'
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker'
import { FieldTypeConfig, FormlyConfig } from '@ngx-formly/core'
import { FieldType } from '@ngx-formly/material'
import { DateTime } from 'luxon'
import { DateValidator } from 'src/types/wizard'
import { MatFormFieldModule } from '@angular/material/form-field'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { MatInputModule } from '@angular/material/input'
import * as _ from 'lodash'

@Component({
  selector: 'gwc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  standalone: true, 
  imports: [
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskDirective,
    NgxMaskPipe, 
    ReactiveFormsModule
  ],
  providers: [
    provideNgxMask()
  ]
})

export class DatepickerComponent extends FieldType<FieldTypeConfig> {
  public messages: {[key: string]: string} = {}
  public min: DateTime|null = null
  public max: DateTime|null = null
  public ready = false
  constructor(
    private formlyConfig: FormlyConfig,
    private ngZone: NgZone
  ) {
    super()
  }

  ngOnInit() {
    // Some old models have the value wrapped in a value key
    if (this.formControl.value?.value) {
      this.formControl.patchValue(this.formControl.value.value)
    }
  }

  
  public dateSelected($event: MatDatepickerInputEvent<DateTime, any>): void {
    this.formControl.patchValue($event.value?.toFormat('MM/dd/yyyy'))
  }

  public getDate(): DateTime | null {
    if (this.formControl.value) {
      return DateTime.fromFormat(this.formControl.value, 'MM/dd/yyyy')
    }

    return null
  }

  // private getValidators() {
  //   this.formControl.addValidators((control: AbstractControl) => {
  //     if (control.value) {
  //       let date = DateTime.fromFormat(control.value, 'MM/dd/yyyy')
  //       return date.isValid ? {} : { 'date': true }
  //     }

  //     return {}
  //   })

  //   if (this.field.props['validators']) {
  //     this.field.props['validators'].forEach((settings: DateValidator) => {
  //       let compare_to: DateTime|null = null
  //       _.set(this.messages, settings.id, settings.message) 

  //       if (settings.date) {
  //         if (settings.date === 'now') {
  //           compare_to = this.checkAdd(settings, DateTime.now())
  //         } else  {
  //           compare_to = this.checkAdd(settings, DateTime.fromFormat(settings.date, 'MM/dd/yyyy'))
  //         }
  //       } else if (settings.key) {
  //         let value = _.get(this.model, settings.key)

  //         if (value) {
  //           compare_to = this.checkAdd(settings, DateTime.fromFormat(value, 'MM/dd/yyyy'))
  //         }
  //       }

  //       if (compare_to !== null) {
  //         if (settings.direction === 'after') {
  //           if (!this.max) this.max = compare_to
  //           else if (this.max > compare_to) this.max = compare_to
  //         } else {
  //           if (!this.min) this.min = compare_to
  //           else if (this.min < compare_to) this.min = compare_to
  //         }

  //         this.formControl.addValidators((control: AbstractControl) => {
  //           let response = {}
  
  //           if (control.value && compare_to !== null) {
  //             let after = DateTime.fromFormat(control.value, 'MM/dd/yyyy') > compare_to
  //             if ((settings.direction === 'after' && after) || (settings.direction === 'before' && !after)) {
  //               _.set(response, settings.id, true) 
  //             }
  //           }
  
  //           return response
  //         })
  //       }
  //     })
  //   }
  // }

  // public checkAdd(settings: DateValidator, date: DateTime) {
  //   if (settings.add !== null && settings.add !== undefined) {
  //     let duration = settings.add.match(/\d+/)

  //     if (duration?.[0]) {
  //       let add

  //       if (settings.add.includes('D')) {
  //         add = {
  //           days: parseInt(duration[0])
  //         }
  //       } else if (settings.add.includes('M')) {
  //         add = {
  //           months: parseInt(duration[0])
  //         }
  //       }

  //       if (add) {
  //         return DateTime.now().plus(add)
  //       }
  //     }
  //   }

  //   return date
  // }

  public getErrorMessage(): string {
    if (this.formControl.errors) {
      const messages = this.field.validation?.messages
      const errors = this.formControl.errors
      const errors_list = Object.keys(errors)

      if (messages?.[errors_list[0]]) {
        return messages?.[errors_list[0]] as string
      } 
    }

    return 'Please enter a valid date(MM/DD/YYYY).'
  }
}
